<template lang="pug">
.container(ref="container")
</template>

<script>
import * as PIXI from 'pixi.js';
import * as Vector from 'victor';
import { onMounted, ref } from 'vue';

window.__DEBUG__ = false;

class Direction {
  constructor(radian) {
    this.radian = radian;
    this.normalize();
  }

  static fromVec(vec) {
    return new Direction(vec.direction());
  }

  normalize() {
    while (this.radian > 2 * Math.PI) {
      this.radian -= 2 * Math.PI;
    }

    while (this.radian < 0) {
      this.radian += 2 * Math.PI;
    }

    return this.radian;
  }

  toVec() {
    return new Vector(Math.cos(this.radian), Math.sin(this.radian));
  }
}

class BumpCircle {
  constructor(app, options) {
    const { x, y, texture, radius, direction, velocity } = options;

    this.app = app;
    const sprite = new PIXI.Sprite(texture);
    sprite.x = x;
    sprite.y = y;
    sprite.anchor.x = 0.5;
    sprite.anchor.y = 0.5;
    sprite.width = radius * 2;
    sprite.height = radius * 2;
    app.stage.addChild(sprite);

    this.sprite = sprite;
    this.radius = radius;
    this.mass = (4 * Math.PI * this.radius ** 3) / 3;
    this.direction = direction;
    this.standby_velocity = velocity;
    this.current_velocity = velocity;
  }

  update() {
    const friction = 20000;
    const delta_v = friction / this.mass;
    if (this.current_velocity < this.standby_velocity) {
      this.current_velocity += delta_v;
    } else if (this.current_velocity > this.standby_velocity) {
      this.current_velocity -= delta_v;
    }

    let direction_vector = this.direction.toVec();

    // The ball bumps with wall
    if (direction_vector.x < 0 && this.sprite.x - this.radius < 0) {
      direction_vector = direction_vector.multiply(new Vector(-1, 1));
    }

    if (direction_vector.x > 0 && this.sprite.x + this.radius > this.app.renderer.width) {
      direction_vector = direction_vector.multiply(new Vector(-1, 1));
    }

    if (direction_vector.y < 0 && this.sprite.y - this.radius < 0) {
      direction_vector = direction_vector.multiply(new Vector(1, -1));
    }

    if (direction_vector.y > 0 && this.sprite.y + this.radius > this.app.renderer.height) {
      direction_vector = direction_vector.multiply(new Vector(1, -1));
    }

    // Move now
    const delta_x = direction_vector.x * this.current_velocity;
    const delta_y = direction_vector.y * this.current_velocity;
    this.direction = Direction.fromVec(direction_vector);
    this.sprite.x += delta_x;
    this.sprite.y += delta_y;

    // Rotation
    this.sprite.rotation += 0.01;
  }
}

export default {
  name: 'BumpPhysics',
  setup() {
    const container = ref(null);
    onMounted(async () => {
      const app = new PIXI.Application({
        antialias: true,
        width: window.innerWidth,
        height: window.innerHeight,
      });
      container.value.appendChild(app.view);

      app.loader.add('bunny', require('@/assets/bubble/bunny.png')).load((loader, resources) => {
        const bubblesOptions = [
          {
            x: app.renderer.width / 2,
            y: app.renderer.height / 2,
            texture: resources.bunny.texture,
            radius: 100,
            direction: new Direction(Math.PI / 4),
            velocity: 1.0,
          },
          {
            x: 120,
            y: 100,
            texture: resources.bunny.texture,
            radius: 30,
            direction: new Direction(-Math.PI / 4),
            velocity: 5.0,
          },
        ];

        const bubbles = bubblesOptions.map((options) => new BumpCircle(app, options));

        app.ticker.add(() => {
          bubbles.forEach((bubble) => bubble.update());
          // Collision Detection
          bubbles.forEach((bubbleA, i) => {
            bubbles.forEach((bubbleB, j) => {
              if (i < j) {
                // Detect bump
                const distance = Math.sqrt(
                  (bubbleA.sprite.x - bubbleB.sprite.x) ** 2 +
                    (bubbleA.sprite.y - bubbleB.sprite.y) ** 2,
                );

                if (distance <= bubbleA.radius + bubbleB.radius) {
                  // Collision!
                  const colAngle = Math.atan2(
                    bubbleB.sprite.y - bubbleA.sprite.y,
                    bubbleB.sprite.x - bubbleA.sprite.x,
                  );

                  const aParallelSpeed =
                    bubbleA.current_velocity * Math.cos(bubbleA.direction.radian - colAngle);
                  const aPerpendicularSpeed =
                    bubbleA.current_velocity * Math.sin(bubbleA.direction.radian - colAngle);
                  const bParallelSpeed =
                    bubbleB.current_velocity * Math.cos(bubbleA.direction.radian - colAngle);
                  const bPerpendicularSpeed =
                    bubbleB.current_velocity * Math.sin(bubbleA.direction.radian - colAngle);

                  const aNewSpeed =
                    ((bubbleA.mass - bubbleB.mass) * aParallelSpeed +
                      2 * bubbleB.mass * bParallelSpeed) /
                    (bubbleA.mass + bubbleB.mass);
                  const bNewSpeed =
                    ((bubbleB.mass - bubbleA.mass) * bParallelSpeed +
                      2 * bubbleA.mass * aParallelSpeed) /
                    (bubbleA.mass + bubbleB.mass);

                  bubbleA.direction = Direction.fromVec(
                    new Direction(colAngle)
                      .toVec()
                      .multiply(new Vector(aNewSpeed, aNewSpeed))
                      .add(
                        new Direction(colAngle + Math.PI / 2)
                          .toVec()
                          .multiply(new Vector(aPerpendicularSpeed, aPerpendicularSpeed)),
                      ),
                  );
                  bubbleB.direction = Direction.fromVec(
                    new Direction(colAngle)
                      .toVec()
                      .multiply(new Vector(bNewSpeed, bNewSpeed))
                      .add(
                        new Direction(colAngle + Math.PI / 2)
                          .toVec()
                          .multiply(new Vector(bPerpendicularSpeed, bPerpendicularSpeed)),
                      ),
                  );
                  bubbleA.current_velocity = Math.sqrt(aNewSpeed ** 2 + aPerpendicularSpeed ** 2);
                  bubbleB.current_velocity = Math.sqrt(bNewSpeed ** 2 + bPerpendicularSpeed ** 2);
                }
              }
            });
          });
        });
      });
    });

    return { container };
  },
};
</script>

<style lang="stylus" scoped>
.container
  position absolute
  margin 0
  top 0
  left 0
</style>
